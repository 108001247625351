<template>
  <div>
    <v-container>
      <v-row>
        <template  v-for="(item, index) in mainViews">
          <v-col  md="4" :key="index" v-if="returnAbility(item.ability)">
            <v-card :dark="$store.state.isDarkMode" color="#757575" height="100" @click="$router.push(item.routerLink)">
              <v-card-text class=" d-flex justify-center align-center">
                {{ $t(item.name) }}
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-icon size="25" >mdi-subdirectory-arrow-right</v-icon>
              </v-card-actions>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mainViews: [
        {
          name: "educational year",
          routerLink: "/educationalYear",
          ability: "educational-year:index",
        },
        {
          name: "terms",
          routerLink: "/terms",
          ability: "term:index",
        },
        {
          name: "terms type",
          routerLink: "/termTypes",
          ability: "term-type:index",
        },
        {
          name: "calsses",
          routerLink: "/grades",
          ability: "grade:index",
        },
        {
          name: "classes type",
          routerLink: "/gradeTypes",
          ability: "grade-type:index",
        },
        {
          name: "floors",
          routerLink: "/floors",
          ability: "floor:index",
        },
        {
          name: "rooms",
          routerLink: "/rooms",
          ability: "room:index",
        },
        {
          name: "education class",
          routerLink: "/educationClass",
          ability: "edu-class:index",
        },
        {
          name: "subjects",
          routerLink: "/subjects",
          ability: "subject:index",
        },
        {
          name: "Grade Structure",
          routerLink: "/gradeStructure",
          ability: "grade-structure:index",
        },
        {
          name: "Backup",
          routerLink: "/backup",
          ability: "backup:index",
        },
        {
          name: "Log",
          routerLink: "/log",
          ability: "log:report",
        },
        {
          name: "Variables",
          routerLink: "/variables",
          ability: "school_variable:index",
        },
        
        {
          name: "Taxes",
          routerLink: "/taxes",
          // ability: "",
        },
        {
          name: "settings",
          routerLink: "/settings",
          ability: "settings:index",
        },
        // {
        //   name: "Currencies",
        //   routerLink: "/currencies",
        //   ability: "currencies:index",
        // },
      ],
    };
  },
};
</script>

<style>

</style>